<template> 
    <div class="login-wrap" v-wechat-title="$route.meta.title">
        <div class="ms-login">
            <div class="ms-title">
                <img class="logo left" src="@/assets/title.png" style="width:260px;"/>
                
            </div>
            <Tabs value="account" style="width:100%;height:90%">
                    <TabPane :label="form.tabnm1" name="account" icon="md-contacts">     
                    <div   ref="login"   class="ms-content">
                        <div style="  display:flex;justify-content:between ">
                           
                            <el-input v-model="form.username" :placeholder="form.usertip" autofocus=true >                      
                                <template slot ="prepend" ><i class="el-icon-user" style="color:#409EFF"></i></template>
                            </el-input>
                        </div>
                        <div style=" margin-top:20px;display:flex;justify-content:between ">
                            
                            <el-input
                                show-password
                                :placeholder="form.pwdtip"
                                autofocus=true
                                ref="pwd"
                                v-model="form.password"
                            >
                                <template slot ="prepend" ><i class="el-icon-lock" style="color:#409EFF"></i></template>
                            </el-input>
                        </div> 
                        <div style=" margin-top:20px;display:flex;justify-content:between ">
                            <el-input
                                :placeholder="form.validtip"
                                ref="holder"
                                @keyup.enter.native="normalLogin"
                                style="width:64%"
                                v-model="validateCompute"
                            >
                                <template slot ="prepend" ><i class="el-icon-s-check" style="color:#409EFF"></i></template>
                            </el-input>
                            <span style="margin-left:3px;height:40px">
                            <el-image :src="codeImg"   @click="clickImg" style="height:40px"/>
                            </span>
                        </div>
                        <div style=" margin-top:20px;display:flex;justify-content:between ">                           
                            <el-checkbox  class="remember" v-model="remchk"> 
                                <span style="font-size:10px;word-wrap:break-word">{{form.remember}} </span>                            
                            </el-checkbox>                           
                        </div>
                                            
                        <Button :loading="loading" @click="normalLogin" type="primary"  icon="md-desktop" style=" margin-top:20px;width:350px;margin-left:0px;height:40px;">
                            <span v-if="!loading">{{form.loginName}}</span>
                            <span v-else>{{form.loginName2}}</span>
                        </Button>
                        <!--Button type="primary" icon="md-person-add" @click="userRegister">{{form.register}}</！Button-->             
                    </div>
                </TabPane>
                <TabPane disabled :label="form.tabnm2" name="msgvalidate" icon="ios-mail-outline">
                    <div style=" margin-top:8px ">
                        <el-input :placeholder="form.telno" v-model="msgcode.phoneno" v-enter-number style="width:380px;margin-left:10px;vertical-align:center">
                            <el-select v-model="msgcode.area" slot="prepend" style="width:160px; " :placeholder="form.seltip"  :filterable="filter">
                                <el-row v-for="item in tabCol" :key='item.key' style="width:240px">
                                    <el-col :span="24">
                                        <el-option v-if="chklang==='English'" :label="item.AREACODE" :value="item.CODE" ></el-option> 
                                        <el-option v-else  :label="item.AREACODE" :value="item.CODE" ></el-option> 
                                    </el-col>
                                </el-row>
                            </el-select>                              
                        </el-input> 
                    </div>
                    <div style=" margin-top:20px;display:flex;justify-content:between ">
                        <el-input :placeholder="form.teltip" v-model="msgcode.validmsg" v-enter-number style="width:235px;margin-left:10px;"></el-input> 
                        <el-button v-show="sendAuthCode" type="primary" style="width:130px;margin-left:14px;" @click="getAuthCode">{{form.code}}</el-button>
                        <el-button disabled v-show="!sendAuthCode" type="primary" style="width:130px;margin-left:14px;" >{{auth_time}}秒后重新发送</el-button>
                    </div>
                    <div style=" margin-top:20px; ">
                        <el-checkbox disabled style="margin-left:14px; " :checked="true"> </el-checkbox> 
                        <span style="font-size:10px;">我已阅读并同意<el-link @click="userProtocol" :underline="false" style="color:#409EFF">《景荣电子用户服务协议》</el-link>和<el-link :underline="false" style="color:#409EFF" @click="userPriv">《隐私政策》</el-link></span>     
                    </div>                                              
                    <Button type="primary" style=" margin-top:20px;width:380px;margin-left:10px;height:40px;" icon="ios-mail-outline" @click="btnMsg">
                        <span style="font-size:14px;">{{form.loginName}}</span>
                    </Button>                        
                </TabPane>
            </Tabs> 
            <div class="ms-title">
                <div style='display:flex'>
                    <div style="width:100px; white-space:nowrap;text-overflow:ellipsis;overflow:hidden;" :title="form.forget_pwd"> 
                        <i class="iconfont icon-wentizhaohui" style="color:#409eff"></i>
                        <a href="javascript:void(0)" @click="findPassword" style="width:74px" > {{form.forget_pwd}} </a>
                        <Divider type="vertical" class="divcolor"/> 
                    </div>
                    <div style="width:98px; text-align:left" >
                        <i class="iconfont icon-yonghukaihu" style="color:#409eff"></i>
                        <a href="javascript:void(0)" @click="userRegister" style="font-size:10px;"> 供应商注册</a>
                    </div>
                    <!-- <div style="width:120px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden; " :title="form.userprotocol">
                        <a href="javascript:void(0)" @click="userProtocol"  > {{form.userprotocol}} </a>
                        <Divider type="vertical" class="divcolor" />   
                    </div> 
                    <div style="width:98px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden; " :title="form.private">
                        <a @click="userPriv"> {{form.private}} </a>
                        <Divider type="vertical" class="divcolor"/>  
                    </div>
                    <div style="width:88px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden; " :title="form.help">
                        <a @click="helpDoc"> {{form.help}} </a>
                         
                    </div> -->
                    <!-- <div style="width:72px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden; " :title="form.wxlogin">
                        <a href="javascript:void(0)" @click="wxShow" style="float:right">
                            <el-image src="/assets/authorize/wx.png"  style="height:25px;margin-left:10px;"></el-image> {{form.wxlogin}} 
                        </a>
                    </div> -->
                </div>
            </div>  
            <!-- <div class="ms-title"  >
                <el-link @click="wxShow" :underline="false" >
                    <el-image fit="scale-down" src="/assets/authorize/wx-login.png"  style="height:35px;width:35px; "></el-image> 
                </el-link>

                <el-link @click="aliShow" :underline="false" style="margin-left:20px;">
                    <el-image fit="fill" src="/assets/authorize/alipay-login.png"  style="height:40px;width:40px; "></el-image> 
                </el-link>
            </div> -->
            <!-- <Modal v-model="phoneModal"   scrollable :title="form.mobile_app"  
                    :styles="{top: '3px'}"  width="420px"  footer-hide>
                <div style=" text-align:center;vertical-align:center; ">
                    <img src="@/assets/kreco_phone.jpg"> 
                </div> 
                <div style=" margin-top:2px; text-align:center;vertical-align:center;font-weight:600;font-size:18px; ">
                    {{form.wxtip}}
                </div>              
            </Modal>    -->
        </div>
        <el-dialog :visible.sync="dialogVisible" width="600px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" >
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{elTitle}}</span>
            </div>
            <div v-html="comment" style="height:400px; overflow-y:auto"> </div>
            <span slot="footer" :style="{'height':'40px' }" >
                <el-button size="small" type="primary" @click="dialogVisible = false">同意并继续</el-button>
            </span>
        </el-dialog>
        <!-- 微信登录 -->
        <el-dialog :visible.sync="showWx" width="600px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false"  >
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">微信登录</span>
            </div>
            <iframe
                :src="wx_url"
                frameborder="0"
                width="100%"
                height="400px"
                scrolling="auto"
                >
            </iframe>
        </el-dialog>
        <!-- 支付宝登录 -->
        <el-dialog  :visible.sync="showAlipay" width="700px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" top="2vh" >
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">支付宝登录</span>
            </div>
            <iframe
                :src="ali_url"
                frameborder="0"
                width="100%"
                height="680px"
                scrolling="auto"
                sandbox
                >
            </iframe>
        </el-dialog>
    </div>
</template>

<script>
 import { Message } from 'iview' 
  import {getDate} from '../api/Select'
 import {getBaseData} from '../api/user'
    export default {
        name: "Login",
        data(){
            return {
                chklang:'',
                sendAuthCode:true,/*布尔值，通过v-show控制显示 获取按钮 还是 倒计时  */
                auth_time: 0, /*倒计时 计数器*/
                loading:false,
                dialogVisible:false,
                enpassword:'',
                validateKey:'',
                codeImg:'',
                foothide:false,
                showWx:false,
                phonesrc:'@/assets/kreco_phone.jpg',
                filter:true,
                remchk:false,
                showAlipay:false,
                msgcode:{
                    phoneno:'',
                    area:'',
                    validmsg:'',
                },
                wx_url:'https://open.weixin.qq.com/connect/qrconnect?appid=wxad557d574cd2196c&redirect_uri=http://www.ipskre.com/%23/loginwx&response_type=code&scope=snsapi_login&state=logon' +'#wechat_redirect',
                ali_url:'https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=2021002174688229&redirect_uri=http%3A%2F%2Fwww.ipskre.com%2F%23%2Flogin_alipay&scope=auth_user&state=back&t='+getDate('year'),
 
                phoneModal:false,
                tabCol:[], //Select 数据集
                zhArr:{} ,//简体对象
                enArr:{} ,//英文对象
                zhSel:[] ,//选择器简体数组
                enSel:[] ,//选择器英文数组
                comment:'',
                elTitle:'',
                form:{
                    username:"",
                    password:"",
                    validate:'',
                    seltip:'',//请选择 
                    telno:'',//请输入手机号
                    teltip:'',//输入6位手机短信校验码
                    code:'',//获取校验码
                    loginName: '',//登录
                    loginName2: '',//登录中...
                    register: '',//注册
                    validtip:'',//请输入验证码
                    userprotocol:'',//用户服务协议
                    forget_pwd:'', //找回密码
                    private:'',//隐私协议
                    help:'',//帮助
                    wxlogin:'',//微信登录
                    wxtip:'',//请使用微信扫描上面二维码
                    tabnm1:'', //账户密码登录
                    tabnm2:'',//短信验证码登录
                    usertip:'',//请输入用户名
                    pwdtip:'', //请输入密码
                    remember:''  //记住我
                }
            }
        },
        computed: {
          validateCompute:{
              get(){
                  return this.form.validate
              },
              set(val){
                  this.form.validate =val.toUpperCase() 
              }
          }  
        },
        mounted() {
            this.clickImg();  
            this.form.username =this.$cookies.get('v_loginuser')
            if  (this.$cookies.get('v_remember')==='Y' ){
                this.form.password =this.$cookies.get('v_pwd')
                this.remchk =true
            }
            this.msgcode.area ='305' //中国(+86)
        },
        created () {
           this.langSet(); 
           //国家代号
           getBaseData(this.$store.state.nologinUrl, '','','','','v_sys_base'," and parentno in (select code from sys_base where parentno='238')").then(res=>{
                this.tabCol=res.data.result
            })
           //初始化语言字段 
           getBaseData(this.$store.state.nologinUrl, 'idseq','19502','','','v_sys_form_fld','').then(res=>{
                for (let k=0 ;k <res.data.result.length;k++){
                    this.zhArr[res.data.result[k].FLDNO]=res.data.result[k].FLDNM  
                    this.enArr[res.data.result[k].FLDNO]=res.data.result[k].FLDEN   
                }
                //console.log('arr:'+this.chklang) 
                this.toggleLang()
            });                                            
        },
        methods:{
            helpDoc(){
                let routeUrl = this.$router.resolve({
                    path: '/helper',
                    query: { },
                });
                window.open(routeUrl.href, '_blank');
            },
            //用户协议
            userProtocol(){
                this.dialogVisible=true
                getBaseData(this.$store.state.baseUrl+'auth/login/getClobData','IDSEQ','39085','lstseq','1','v_bs_frontdtl','').then(res=>{
                    this.elTitle='景荣电子平台用户服务协议'
                    this.comment =res.data.result[0].note
                })
            },
             //隐私协议
            userPriv(){
                this.dialogVisible=true
                getBaseData(this.$store.state.baseUrl+'auth/login/getClobData','IDSEQ','39085','lstseq','2','v_bs_frontdtl','').then(res=>{
                    this.elTitle='景荣电子平台用户隐私协议'
                    this.comment =res.data.result[0].note
                })
            },
            //短信登录
            btnMsg() {
                let  that =this
                if (!this.msgcode.validmsg){
                    Message.error('请输入短信校验码')
                }else if (!this.msgcode.phoneno){
                    Message.error('请输入手机号码')
                }else {
                    //短信验证码 10分钟有效 及合法性判断
                    this.$axios({
                        method:'post', 
                        url: this.$store.state.baseUrl+'auth/login/chkMessage' ,
                        data:{  //get这里应为params //请求参数
                            telno :this.msgcode.phoneno,
                            code :this.msgcode.validmsg,
                        },
                    }).then(res =>{
                        if (res.data.code === '200'){
                            that.$cookies.set('admin_token','sample_token');
                            that.$cookies.set('v_username',res.data.userno); //用户编码                     
                            that.$cookies.set('v_usernm',res.data.usernm); //用户名称                  
                            that.$cookies.set('v_password',res.data.enpassword); //加密后的密码    
                            that.$cookies.set('idseq',res.data.idseq);          
                            that.$router.replace('/dashboard/main');
                        }else{
                            Message.error(res.data.message)
                        }
                    }) 

                }
            },
            //获取验证码
            getAuthCode() {
                if (!this.msgcode.area){
                    Message.error('请选择手机号区域')
                }else if (!this.msgcode.phoneno){
                    Message.error('请输入手机号码')
                }else {
                    console.log(this.msgcode.phoneno+":"+this.msgcode.area)
                    let area_ =this.msgcode.area==='305'?'china':'foreign'
                    //消息验证
                    this.$axios({
                        method:'post', 
                        url: this.$store.state.baseUrl+'auth/login/sendMessage' ,
                        data:{  //get这里应为params //请求参数
                            telno :this.msgcode.phoneno,
                            area :area_,
                            areano:this.msgcode.area
                        },
                    }).then(res =>{
                        if (res.data.code === 'OK'){
                            this.sendAuthCode = false;
                            this.auth_time = 60;
                            var auth_timetimer =  setInterval(()=>{
                                this.auth_time--;
                                if(this.auth_time<=0){
                                    this.sendAuthCode = true;
                                    clearInterval(auth_timetimer);
                                }
                            }, 1000);
                        }else{
                            Message.error(res.data.message)
                        }
                    }) 

                }
            },
            //语言设置
            langSet(){
                if ((navigator.language || navigator.browserLanguage).toLowerCase().includes('zh-')>-1){
                    this.chklang='中文'
                    this.$store.state.lang=this.chklang
                }else{
                    this.chklang='English'
                    this.$store.state.lang=this.chklang
                }                
            },
            //切换语言
            toggleLang(){
                if (this.chklang==='English'){
                    Object.assign(this.form,this.enArr) 
                    this.$store.state.lang=this.chklang
                }else{
                    Object.assign(this.form,this.zhArr)
                    this.$store.state.lang=this.chklang
                }                
            },
            //用户注册
            userRegister(){
                //this.$router.replace('/user_register');
                this.$router.push({path:'/user_register'});
            },
            //找回密码
            findPassword(){
                //this.$router.replace('/get_forget');
                this.$router.push({path:'/get_forget'});  //push 会在history留下记录，replace不会
            },
            //微信登录
            wxShow(){
                this.showWx =true
            },
            aliShow(){
                //this.showAlipay=true
                window.location.href=this.ali_url
            },
            //点击图片重新生成
            clickImg(){
                //this.form.url =this.$store.state.baseUrl+'auth/login/getValidateImg?t='+Math.random();
                var vm =this
                let url_ =this.$store.state.baseUrl+'auth/login/getValidateImg?t='+Math.random();
                this.$axios({
                    method: 'get',
                    url:url_ ,
                    responseType: 'arraybuffer',
                }).then(res=>{ 
                    //获取后端接口定义的response header中的参数
                    vm.validateKey = res.headers.validatecode 
                    //console.log(vm.validateKey+"///"+JSON.stringify(res.headers))
                    //把二进制流转化为 base64 就不需要调用二次接口，解决获取的验证字符和生成图片不是同一接口问题
                    return 'data:image/jpeg;base64,' + btoa(
                        new Uint8Array(res.data)
                            .reduce((data, byte) => data + String.fromCharCode(byte), '')
                        );    
                }).then(data => {
                    this.codeImg = data;
                }).catch(ex => {
                    console.error(ex);
                });
            } ,
            //登录
            normalLogin(){
              if (!this.form.username){
                  Message.error('用户名不能为空')
              }else if (!this.form.password){
                  Message.error('密码不能为空')
              }else if (!this.form.validate){
                  Message.error('验证码不能为空')
              } else {
                if (this.form.validate!==this.validateKey)   {
                    Message.error('验证码不正确')
                }else {                
                    const that=this;
                    that.loading=true;
                    let url_ =this.$store.state.baseUrl;
                    // 密码加密
                    this.$axios({
                        method:'post',
                        url: url_+'auth/encodePwd' ,
                        data:{  //get这里应为params //请求参数
                            password :this.form.password
                        },
                        //withCredentials:true, //局部携带cookie
                        headers:{} //如果需要添加请求头可在这写
                    }).then(res => {  //res是返回结果
                        this.enpassword = res.data.message  //存数据
                        //登录验证
                        this.$axios({
                            method:'post',
                            url: url_+'auth/login' ,
                            data:{  //get这里应为params //请求参数
                                password :this.form.password,
                                username :this.form.username
                            },
                        }).then(res =>{
                            if (res.data.code === '100'){
                                setTimeout(()=>{
                                    that.$cookies.set('admin_token','sample_token');
                                    that.$cookies.set('v_username',res.data.userno); //用户编码
                                    that.$cookies.set('v_loginuser',this.form.username); //用户编码
                                    that.$cookies.set('v_usernm',res.data.usernm); //用户名称
                                    that.$cookies.set('idseq',res.data.idseq);
                                    that.$cookies.set('v_sys',res.data.admin);
                                    that.$cookies.set('v_password',this.enpassword); //加密后的密码
                                    that.$cookies.set('v_pwd',this.form.password); //原密码
                                    
                                    if (this.remchk==false){
                                        that.$cookies.set('v_remember','N');
                                    }else{
                                        that.$cookies.set('v_remember','Y');
                                    }
                                    that.$nextTick(() => {
                                        that.$router.replace('/dashboard/main');
                                    });
                                    
                                },1000)      
                            }else{
                                Message.error(res.data.message)
                                that.loading=false 
                            }
                        })
                    }).catch(err => { //请求失败就会捕获报错信息
                        //err.response可拿到服务器返回的报错数据       
                    }) 
                    /* */
                  }
               }
            },
        }
    }
</script>
<style>
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
        border-color :#3d77eb;
    }
    .el-checkbox__input.is-disabled+span.el-checkbox__label{
        color:#3d77eb
    }
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    }
</style>
<style scoped lang="less">
.login-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    background: url(../assets/KRECO.png) repeat 0px 0px; 
}
.ms-title {
    width: 100%;
    line-height: 50px;
    text-align: center;
    font-size: 10px;
    color: #2b85e4;
   /* border-bottom: 1px solid #ddd; */
}
.left{
    float:left
}
.right{
    float:right;
    margin-right:5px;
}
.ms-login {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 410px;
    margin: -250px 0 0 -205px;
    border-radius: 5px;
    background: rgb(245,245,248);//#f0f8ff;
    overflow: hidden;
} 
.ms-content {
    padding: 10px 30px;
}
.login-btn {
    height: 42px;
    display: flex;
    //justify-content: space-around;
    margin-top:20px;
}
.login-btn button {

    height: 36px;
    margin-bottom: 3px;
}
 
.logo {
  width: auto;
  height: 50px;
  margin-bottom:10px;
}
.remember {
    width: 100%;
    line-height: 20px;
    float:left;
    font-size: 8px;
    color: #2b85e4;
    font-weight: 500; 
}
.divcolor{
    background-color: #5cadff;
    width:2px;
}
</style>
